.wrapper-notification {
  background-color: #004270;
}

.wrapper-notification h2,
.wrapper-notification p {
  color: white;
  text-align: center;
}

.wrapper-notification h2 {
  font-weight: 300;
  padding-top: 0;
  font-size: 18pt;
  padding: 10px 0;
}
.wrapper-notification h2 b {
  font-weight: 500;
  padding-top: 0;
  font-size: 18pt;
}

.wrapper-notification h3 {
  font-weight: 400;
  font-size: 14pt;
  color: white;
  text-align: center;
  padding: 10px 0;
}
.wrapper-notification h3::after {
  margin: auto;
  margin-top: 7px;
}
.wrapper-notification h3 b {
  font-weight: 500;
  padding-top: 0;
  font-size: 14t;
}
.wrapper-notification a {
  background-color: #febe10;
  padding: 10px 30px;
  max-width: max-content;
  border-radius: 4px;
  margin: 1em auto 0;
  font-size: 11pt;
  font-weight: 700;
  transition: 0.3s;
}
.wrapper-notification a:hover {
  color: white;
}

.wrapper-notification .bg-line1 {
  position: absolute;
  top: 0;
  right: 0;
}
.wrapper-notification .bg-line2 {
  position: absolute;
  top: 15%;
  right: 1%;
}

.wrapper-notification .bg-line3 {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotate(180deg);
}

.wrapper-notification h4{
  color:white;
}
@media screen and (max-width: 900px) {
  .wrapper-notification a {
    margin-top: 1em;
  }

  .wrapper-notification > div {
    width: 90%;
    margin: auto;
  }
}
