/* General wrapper for the news section */
.wrapper-noticias {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory; /* Agrega scroll snapping */
  margin: 0 auto;
  padding: 10px 0;
  scroll-behavior: smooth;
}

/* Hide scrollbar */
.wrapper-noticias::-webkit-scrollbar {
  display: none;
}

/* Flex container for news items */
.flex-noticias {
  display: flex;
  gap: 20px; /* Use gap for spacing between items */
  align-items: stretch;
  justify-content: flex-start;
}

/* Individual news item */
.flex-noticias > div {
  scroll-snap-align: start; /* Cada ítem se alinea al inicio del contenedor */
  width: 300px;
  min-width: 300px;
  background: #fafafa;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.flex-noticias > div:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Image container */
.noticias-imagen {
  width: 100%;
  height: 170px;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

/* Image styling */
.noticias-imagen img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* Title of the news item */
.noticias-imagen + .layout9 .title {
  padding-top: 20px;
  font-size: 15px;
  font-weight: bold;
  color: #004270;
  height: 70px;
}

/* Link button styles */
.wrapper-noticias a {
  display: inline-block;
  background-color: #004270;
  padding: 10px;
  border-radius: 4px;
  font-size: 9pt;
  font-weight: 700;
  color: white;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
  margin: 10px 0 20px;
}

.wrapper-noticias a:hover {
  background-color: #2f9eed;
}

/* Scroll navigation arrows */
.gallery-indicators-noticias .material-icons {
  color: #004270;
  font-size: 40px;
  cursor: pointer;
  transition: color 0.3s;
}

.gallery-indicators-noticias .material-icons:hover {
  color: #febe10;
}

/* Asegura que el botón esté siempre en la parte inferior */
.wrapper-noticias-button {
  margin-top: auto; /* Empuja el botón hacia abajo */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  .flex-noticias {
    gap: 15px; /* Smaller gap on mobile */
  }

  .flex-noticias > div {
    width: 260px; /* Adjust width on smaller screens */
  }

  .gallery-indicators-noticias .material-icons {
    font-size: 30px; /* Smaller icons on mobile */
  }
}
