.tag1-retoma {
  color: #004270 !important;
  font-weight: 700;
  font-size: 18pt;
  padding: 0;
  margin: 0;
  margin-top: 1em;
}

.tag2-retoma {
  color: #004270 !important;
  font-weight: 700;
  font-size: 30pt;
  padding: 0;
  margin: 0;
}

.tag3-retoma {
  font-weight: 200;
  padding: 0;
  margin: 0;
  font-family: "Red Hat Display";
  font-size: 17pt;
}

.content-select select {
  appearance: none;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  color: #374151;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
  width: 100%;
  max-width: 400px;
  text-align: center;
  background: #2791dd;
  color: white;
  font-size: 15pt;
}
.content-select select:hover {
  background-color: #4197d5; /* Fondo al pasar el cursor */
}

.content-select select:focus {
  border-color: #6366f1; /* Borde al enfocar */
  box-shadow: 0 0 5px rgba(99, 102, 241, 0.5); /* Efecto de enfoque */
}

.content-select select:disabled {
  background-color: #4a4a4a; /* Fondo para select deshabilitado */
  color: #004270; /* Texto para select deshabilitado */
  cursor: not-allowed;
}


@media screen and (max-width: 900px) {
    .content-select select {
    max-width: 100%;
    }
  }