*,
body {
  margin: 0;
  font-family: "Red Hat Display", sans-serif;
}
.columns-validaciontitulo .block-column .col3 > div:first-child {
  width: 50%;
}
.columns-validaciontitulo .block-column .col3 > div:nth-child(2),
.columns-validaciontitulo .block-column .col3 > div:nth-child(3) {
  width: 24%;
}
.iconos-validable .material-icons {
  color: white;
  font-size: 45px;
}
.link-with-arrow {
  display: inline-flex;
  align-items: center;
  margin: 0 !important;
  color: #414141 !important;
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.3s ease;
  background-color: transparent !important;
}

.link-with-arrow a {
  font-size: 10pt;
  font-weight: 500;
}

.link-with-arrow::after {
  content: "→"; /* Flecha */
  margin-left: 0.5rem;
  font-size: 1.2em;
  transition: transform 0.3s ease;
}

.link-with-arrow:hover,
.link-with-arrow:hover a {
  color: #007bff !important; /* Cambia el color al pasar el cursor */
}

.link-with-arrow:hover::after {
  transform: translateX(4px); /* Desplaza la flecha ligeramente */
}

.nobutton a {
  background-color: transparent !important;
  padding: 0 !important;
  border-radius: 0px;
  margin: 0 !important;
  border-bottom: none !important;
  font-weight: 300 !important;
  max-width: none !important;
}
.nobutton p {
  padding: 3px 0;
}
.button {
  background-color: #febe10;
  padding: 10px 15px;
  max-width: max-content;
  border-radius: 4px;
  font-size: 10pt;
  font-weight: 700;
  text-decoration: none;
  color: #004270 !important;
  transition: background-color 0.3s, color 0.3s;
}

.button:hover {
  background-color: #f0a500;
  color: white !important;
}

.wrapper-blockimagecabecera .button1 {
  color: white !important;
}
.button1 {
  background-color: transparent !important;
  color: #004270 !important;
  max-width: none !important;
  border-radius: 0px !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
}

.button1:hover {
  color: #febe10 !important;
}
.bluebg {
  background-color: #004270;
}
.bluebg .accordion {
  border-bottom: 1px solid rgb(255 255 255 / 20%);
}
.bluebg .accordion div {
  color: white;
}

.graybg {
  background-color: #e6e6e6;
}
.gray1bg {
  background-color: #fafafa;
}
.bluebg a,
.bluebg p,
.bluebg h2,
.bluebg h3,
.bluebg h4,
.bluebg li {
  color: white;
}

.bluebg .accordion-content h3,
.bluebg .accordion-content h2 {
  color: #004270;
}

.bluebg ul li {
  color: white;
}
a:hover {
  color: #febe10;
}
ol {
  color: #004270;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul {
  padding: 0;
  margin: 0;
}

ul li {
  position: relative;
  color: #004270;
  padding: 3px 0;
  font-weight: 300;
  padding-left: 25px; /* Espacio para el ícono */
  list-style: none; /* Quitar viñetas */
}
ul li p {
  padding: 0;
}

ol li {
  position: relative;
  color: #004270;
  padding: 3px 0;
  font-weight: 300;
  padding-left: 6px; /* Espacio para el ícono */
}

/* Solo aplica el ícono a los li que NO contienen otra lista */
ul > li:not(:has(ul))::before {
  content: "chevron_right";
  font-family: "Material Icons";
  color: #febe10;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* Ajustar margen de sublistas */
ul li ul {
  margin-left: 20px;
  padding-left: 0;
}

.layout6 {
  width: 60%;
  margin: auto;
}
.layout8 {
  width: 85%;
  margin: auto;
}

.layout9 {
  width: 90%;
  margin: auto;
}
.col6 > div {
  width: 16%;
}
.col5 > div {
  width: 19%;
}
.col4 > div {
  width: 24%;
}
.col3 > div {
  width: 32%;
}
.col2 > div {
  width: 48%;
}
.padding {
  padding: 4em 0;
}
.padding2 {
  padding: 2em 0;
}
.margin {
  margin-top: 3em;
}

.relative {
  position: relative;
}
p {
  font-size: 12.2pt;
  font-weight: 320;
  color: #004270;
  line-height: 1.4;
  padding: 8px 0;
}

p b {
  font-weight: 700;
}
.iconsize20 {
  font-size: 20pt;
}

a {
  color: #004270;
  text-decoration: none;
  display: block;
  font-weight: 300;
  line-height: 1.4;
}

.rtecenter {
  text-align: center;
}
.rtecenter::after {
  margin: auto;
  margin-top: 10px;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
.width400 {
  max-width: 400px;
  margin: auto;
  width: 90%;
}
.width500 {
  max-width: 500px;
  width: 90%;
}
.width700 {
  max-width: 700px;
  width: 90%;
}

h1 {
  font-size: 26pt;
  font-weight: 500;
  padding: 15px 0;
  color: #004270;
  line-height: 1.1;
  font-family: "Montserrat", sans-serif;
}

h1 b,
h1 strong {
  font-weight: 900;
}

h2 {
  font-size: 20pt;
  font-weight: 400;
  padding: 15px 0;
  color: #004270;
  line-height: 1.1;
  font-family: "Montserrat", sans-serif;
}

h2 b,
h2 strong {
  font-weight: 700;
  font-size: 24pt;
  font-family: "Montserrat", sans-serif;
}

h3 {
  font-size: 18pt;
  font-weight: 400;
  padding: 15px 0;
  color: #004270;
  line-height: 1.1;
  font-family: "Montserrat", sans-serif;
}
h3 b,
h3 strong {
  font-weight: 700;
  font-size: 20pt;
  font-family: "Montserrat", sans-serif;
}

h3::after {
  content: " ";
  display: block;
  background-color: #eaab00;
  height: 3px;
  width: 40px;
  margin-top: 10px;
}
h4 {
  color: #4d92c2;
  font-weight: 500;
  padding: 15px 0;
  font-size: 14pt;
  font-family: "Montserrat", sans-serif;
}

h4 b,
h4 strong {
  font-weight: 800;
  font-size: 16pt;
}

.table-calidad div {
  color: #004270;
}
.bg-line1,
.bg-line2,
.bg-line3,
.bg-line4 {
  max-width: 100px;
}
.circleimg {
  justify-content: space-around;
}
.circleimg img {
  width: 110px;
  margin: auto;
  padding-top: 10px;
}

.circleimg p {
  font-size: 10pt !important;

  line-height: 1.2;
}
.autoridades {
  align-items: flex-start !important;
}
.autoridades img {
  width: 110px;
}

.autoridades .card {
  background-color: transparent;
  box-shadow: none !important;
}
.contacto p {
  text-align: center;
  font-size: 14pt;
}

.contacto img {
  width: 70px;
  margin: auto;
  filter: contrast(0.1);
  padding-bottom: 7px;
}

.contacto .material-icons {
  color: #e6e6e6;
  font-size: 70px;
}

.contacto div {
  text-align: center;
}

.redes-oferta .flex {
  justify-content: flex-start;
}

.redes-oferta > div {
  padding-bottom: 1em;
}
.redes-oferta img {
  width: 30px;
  padding-right: 10px;
  filter: contrast(0.3);
}
.estudiar-utpl .block-column .col4 > div:first-child {
  background-color: white;
  box-shadow: none;
}
.estudiar-utpl .block-column .col4 > div {
  background-color: #e6e6e6;
  padding: 20px 0;
  box-shadow: 0px 1px 1px 1px #ddd;
  transition: 0.3s;
}

.estudiar-utpl .block-column .col4 > div:hover {
  background-color: #004270;
}

.estudiar-utpl .block-column .col4 > div:hover h3,
.estudiar-utpl .block-column .col4 > div:hover h2,
.estudiar-utpl .block-column .col4 > div:hover p {
  color: white;
}
.lema-contacto p,
.lema-contacto h3 {
  text-align: center;
}

.lema-contacto h3::after {
  margin: auto;
}

.lema-contacto h3 b {
  color: #febe10;
  font-weight: 900;
}

.wrapper-contactoinstitucional,
.wrapper-contactocentros,
.wrapper-contactosocial,
.alumnos-contacto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: wrap;
}

.wrapper-contactoinstitucional > div:first-child,
.wrapper-contactosocial > div:first-child {
  width: 40%;
}
.wrapper-contactoinstitucional > div:last-child,
.wrapper-contactocentros > div:last-child,
.wrapper-contactosocial > div:last-child,
.alumnos-contacto > div:last-child {
  width: 60%;
}

.alumnos-contacto > div:first-child,
.wrapper-contactocentros > div:first-child {
  width: 35%;
}
.wrapper-contactoinstitucional .social {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1em 0;
}

.wrapper-contactoinstitucional .social img {
  width: 35px;
  margin-right: 3em;
}

.wrapper-contactoinstitucional .social a {
  font-size: 13pt;
}

.wrapper-contactoinstitucional .social p {
  font-weight: 900;
  font-size: 15pt;
}
.wrapper-contactoinstitucional .social p::after {
  content: " ";
  display: block;
  background-color: #eaab00;
  height: 3px;
  width: 25px;
  margin: 10px 0 0;
}

.wrapper-contactoinstitucional iframe {
  border: none;
}

.wrapper-contactocentros a {
  background-color: #febe10;
  padding: 10px 30px;
  max-width: max-content;
  border-radius: 4px;
  margin-top: 2em;
  font-size: 11pt;
  font-weight: 700;
  transition: 0.3s;
  color: #004270;
}

.wrapper-contactocentros a:hover {
  background-color: white;
}

.items-centros {
  display: flex;
  align-items: stretch;
  flex-flow: wrap;
  justify-content: space-between;
}

.items-centros > div {
  background-color: white;
  width: 32%;
  margin-bottom: 1em;
  padding-bottom: 1em;
}

.items-centros h3 {
  font-size: 13pt;
  color: #004270;
}
.items-centros h3::after {
  content: " ";
  display: block;
  background-color: #eaab00;
  height: 3px;
  width: 25px;
  margin: 10px 0 0;
}

.items-centros p {
  font-size: 11pt;
  color: #004270;
  line-height: 1;
  padding: 0;
}

.wrapper-contactosocial .wrapper-social img {
  filter: contrast(0.1);
  width: 70px;
}
.wrapper-contactosocial .wrapper-social img:hover {
  filter: contrast(0.5);
}

.wrapper-contactosocial h2 {
  font-size: 30pt;
  font-weight: 300;
}
.wrapper-contactosocial h2 b {
  font-weight: 900;
}
.section-social {
  padding-left: 25%;
}

.others-social {
  background-color: white;
  box-shadow: 0px 1px 1px 1px #e6e6e6;
  position: relative;
  top: -4em;
}
.alumnos-contacto .layout8 {
  width: 100%;
}
.alumnos-contacto .col2 > div {
  background-color: #e8e8e8;
  box-shadow: 0px 1px 1px 1px #e6e6e6;
  padding: 20px 0;
}
.alumnos-contacto h2 {
  font-weight: 300;
}
.alumnos-contacto h2 b {
  font-weight: 900;
}
.alumnos-contacto h3::after {
  content: " ";
  display: block;
  background-color: #febe10;
  height: 3px;
  width: 25px;
  margin: 10px 0 0;
}

.alumnos-contacto a {
  background-color: #febe10;
  padding: 10px 30px;
  max-width: max-content;
  border-radius: 4px;
  margin-top: 1em;
  font-size: 11pt;
  font-weight: 700;
  transition: 0.3s;
}

.alumnos-contacto a:hover {
  background-color: #004270;
  color: white;
}

.servicios-presencial > div.layout8 {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}
.servicios-presencial > div > div {
  width: 22%;
  text-align: center;
  border-bottom: 1px solid #febe10;
  padding: 2em 0 0;
}
.servicios-presencial .material-icons {
  color: #004270;
  font-size: 40px;
}
.wrapper-breadcumbs {
  padding: 1em 0;
}
.wrapper-breadcumbs > div {
  display: flex;
}
.wrapper-breadcumbs .active {
  color: #febe10;
  font-weight: 900;
}

.wrapper-breadcumbs .material-icons {
  color: #febe10;
}
.tec > div {
  justify-content: center;
}

.tec > div > div {
  margin: 20px;
}

.gallery-formacionpermanente {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  align-items: center;
}

.gallery-formacionpermanente .item {
  max-width: 200px;
  margin: 0 20px;
}
.icon20 img {
  max-width: 30px;
  padding-top: 20px;
  opacity: 0.5;
}
.icon50 img {
  max-width: 50px;
  margin: auto;
  padding-bottom: 10px;
}
.icon70 img {
  max-width: 70px;
  margin: auto;
  padding-bottom: 10px;
}

.icon100 img {
  max-width: 100px;
  margin: auto;
  padding-bottom: 10px;
}

.icon150 img {
  max-width: 150px;
  margin: auto;
  padding-bottom: 10px;
}

.icon150-nomargin img {
  max-width: 150px;
  padding-bottom: 10px;
}
.icon200 img {
  max-width: 200px;
  margin: auto;
}

.icon200m img {
  max-width: 200px;
  padding: 10px;
}

.icon300 img {
  max-width: 300px;
  margin: auto;
}
.icon350 img {
  max-width: 350px;
  margin: auto;
}
.icon400 img {
  max-width: 400px;
  margin: auto;
}
.wrapper-matricula .wrapper-blockimage .content a {
  margin: 0 0 0.5em;
}
.wrapper-matricula .wrapper-blockimage > div > div > div > img {
  max-width: 350px;
  margin: auto;
}

.wrapper-simulador img {
  max-width: 350px;
  margin: auto;
}

.bienvenida-rector .padding {
  padding: 0 !important;
}

.gobernanza-autoridades .flex {
  justify-content: center;
}
.wrapper-gobernanza .flip-card {
  width: 150px;
  height: 150px;
}
.wrapper-gobernanza .flip-card-inner {
  box-shadow: none;
}
.wrapper-gobernanza .flip-card-front {
  background-color: white;
}

.wrapper-gobernanza .flip-card-back p {
  font-size: 10pt;
  line-height: 1;
}

.wrapper-autoridades-academicas {
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.wrapper-autoridades-academicas h3,
.wrapper-autoridades-academicas h3 b,
.wrapper-autoridades-academicas h4,
.wrapper-autoridades-academicas h4 b {
  font-size: 13pt;
}

.wrapper-autoridades-academicas > div {
  width: 48%;
  margin-bottom: 1em;
}

.tematicas-prueba .wrapper-modalfixed {
  bottom: -100px;
}

.tec {
  justify-content: center !important;
}
.tec > div {
  margin: 0 1em;
}

.tag-sermas {
  font-size: 26pt;

  font-weight: 300;
}

.tag-sermas b {
  color: #febe10;
  font-size: 44pt;
}

.manual-imagen img {
  margin: 0 2em;
}
.manual-imagen .flex {
  align-items: flex-start;
  justify-content: center;
}
.align-center .col2 {
  align-items: center;
}

.flex-center {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: start;
}
.flex-center > div {
  padding: 10px;
}
.wrapper-matricula h4 {
  padding: 15px 0 10px;
}

.formutpl {
  max-width: 400px;
  margin: auto;
}

.formutpl h1 {
  text-align: center;
}
.formutpl h1 span {
  color: #004270 !important;
}
.formutpl.whitetext h1 span {
  color: white !important;
}

.formutpl span,
.formutpl label {
  color: #004270 !important;
  font-size: 9pt;
}

.formutpl.whitetext span,
.formutpl.whitetext label {
  color: white !important;
  font-size: 9pt;
}
.formutpl a {
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  color: #8a8a8a;
  display: inline-block;
  font-weight: 300 !important;
  font-size: 7pt !important;
}

.formutpl.formutpl.whitetext a {
  color: white;
}
.formutpl ul {
  list-style: none;
}

.formutpl ul li::before {
  content: none;
}
.formutpl li {
  display: block;
  line-height: 1;
  font-size: 8pt;
}
.formutpl .actions {
  text-align: center;
}
.formutpl .actions input {
  background-color: #febe10;
  color: #004270 !important;
  border: none !important;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 2em !important;
  font-weight: 600;
  border-radius: 4px;
}
.formutpl .actions input:hover {
  color: white !important;
}
.formutpl .inputs-list input {
  margin-bottom: 0;
}
.formutpl input {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1em;
}
.introduction-maestria {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.introduction-maestria > div:first-child {
  width: 70%;
}

.introduction-maestria > div:last-child {
  width: 23%;
}
.diferencia-maestria li {
  margin: 0.5em 0;
}
.diferencia-maestria p {
  font-size: 20pt;
}

.button-posgrados {
  margin-top: 1em;
}

.button-posgrados:hover {
  color: white !important;
}
.posgrados-content p {
  font-weight: 400;
  line-height: 1.5;
  padding: 7px 0;
}

.intro-parquecientifico {
  background-position: 97% 90%;
  background-repeat: no-repeat;
  background-color: #004270;
  background-size: 200px;
}

.intro-parquecientifico .wrapper-notification {
  background-color: transparent;
}

.bloques-parquecientifico {
  background-color: #004270;
}
.bloques-parquecientifico .col3 > div > div {
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
}

.bloques-parquecientifico .col3 > div > div h3,
.bloques-parquecientifico .col3 > div > div p {
  width: 90%;
  margin: auto;
}
.wrapper-evaluacion img {
  width: 15px;
  text-align: center;
}
.wrapper-evaluacion-institucional {
  background-position: 100% bottom;
  background-size: auto 70%;
  background-repeat: no-repeat;
}

.grayicon img {
  filter: grayscale(1);
  opacity: 0.8;
}
.grayicon img:hover {
  filter: grayscale(0);
  opacity: 1;
}

.grayicon > div {
  display: flex;
}
.segunda-lengua .icons {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
}
.segunda-lengua .block-column .content a {
  background-color: #e6e6e6;
  box-shadow: 0 1px 1px 1px #ddd;
  height: 180px;
  margin: 0;
  position: relative;
  padding: 0;
  max-width: initial;
}

.segunda-lengua h4 {
  width: 90%;
  margin: auto;
}

.segunda-lengua {
  padding: 2em 0 3em;
}

.segunda-lengua .block-column .content a:hover {
  background-color: #febe10;
  box-shadow: 0 1px 1px 1px #4a4a4a;
}
.segunda-lengua .block-column .content:hover h4,
.segunda-lengua .block-column .content:hover .material-icons {
  color: #4a4a4a;
}
.segunda-lengua .block-column .content h4 {
  color: #004270;
  line-height: 1.3;
  padding: 0;
  text-align: center;
}
.segunda-lengua .block-column .content div {
  text-align: center;
}

.segunda-lengua .block-column .content .material-icons {
  font-size: 30px;
}
.oferta-icons {
  padding-top: 2em;
}
.oferta-icons .col6 > div {
  background-color: rgb(255 255 255 / 50%);
  text-align: center;
  padding: 2em 0;
  border-radius: 4px;
}
.oferta-icons .block-column .col6 > div img {
  max-width: 38px;
  margin: auto;
  margin-bottom: 15px;
}
.oferta-icons .col6 > div p {
  padding: 0;
  font-size: 11pt;
}
.oferta-icons .col6 > div p b {
  margin-top: 2em;
}
.oferta-icons .block-column .col6 > div:hover img {
  filter: brightness(1);
}
.oferta-icons .col6 > div:hover {
  background-color: #004270;
}
.oferta-icons .col6 > div:hover p {
  color: white;
}

.wrapper-docente .flex-center {
  align-items: stretch;
  gap: 10px;
  color: transparent;
}

.wrapper-docente .item {
  width: 240px;
  text-align: center;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  transition: border 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  padding-bottom: 35px;
}

.wrapper-docente .item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Agrega sombra al div */
}

/* Borde inicial */
.wrapper-docente .item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #febe10;
  transition: background 0.3s ease-in-out;
}

/* Borde animado en hover */
.wrapper-docente .item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 4px;
  background: linear-gradient(to right, #004270, #004270);
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.wrapper-docente .item:hover::after {
  width: 100%;
  left: 0;
}

/* Imagen con efecto de zoom */
.wrapper-docente .item img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.wrapper-docente .item:hover img {
  transform: scale(1.05);
}

/* Texto */
.wrapper-docente .item .docente {
  font-size: 1rem;
  color: #004270;
  width: 90%;
  margin: auto;
  padding-top: 10px;
}

.wrapper-docente .item .cargo {
  font-size: 0.8rem;
  color: #555;
}

.wrapper-docente .link-with-arrow {
  position: absolute;
  bottom: 10px;
  left: 60px;
}

.lineas-estrategicas .block-column .col4 > div {
  position: relative;
  padding-bottom: 45px;
}

.lineas-estrategicas .block-column .col4 > div .modal-open {
  position: absolute;
  bottom: 0px;
}

.lineas-estrategicas .block-column .col4 > div a {
  padding: 10px 0px;
}

.pedi-informacion .col2 {
  align-items: center;
}
@media screen and (max-width: 900px) {
  .oferta-icons {
    padding: 0;
  }
  .oferta-icons .col6 > div {
    padding: 10px 0;
  }
  .wrapper-breadcumbs a {
    font-size: 10pt;
  }
  .segunda-lengua .block-column .content a {
    height: auto;
    margin: auto;
  }

  .segunda-lengua .icons {
    position: initial;
  }
  .tec > div {
    margin: 0;
  }
  .layout8,
  .layout6 {
    width: 90%;
    margin: auto;
  }

  h1 {
    font-size: 22pt;
    font-weight: 600;
  }
  h2 b {
    font-weight: 900;
  }
  h2 {
    font-size: 18pt;
  }

  h2 b {
    font-size: 19pt;
  }

  .section-social {
    padding-left: 10%;
  }
  .wrapper-autoridades-academicas > div {
    width: 100%;
  }
  .autoridades > div {
    width: 100%;
  }
  .lema-contacto .layout6 {
    width: 80%;
  }
  .wrapper-contactoinstitucional > div:first-child,
  .wrapper-contactocentros > div:first-child,
  .wrapper-contactosocial > div:first-child,
  .alumnos-contacto > div:first-child {
    width: 100%;
  }
  .wrapper-contactoinstitucional > div:last-child,
  .wrapper-contactocentros > div:last-child,
  .wrapper-contactosocial > div:last-child,
  .alumnos-contacto > div:last-child {
    width: 100%;
  }
  .items-centros {
    padding-top: 2em;
  }
  .items-centros > div {
    width: 100%;
  }

  .col6 > div,
  .col5 > div,
  .col4 > div,
  .col3 > div,
  .col2 > div {
    width: 100%;
  }
  .wrapper-contactosocial h2 {
    font-size: 20pt;
  }
  .others-social {
    position: initial;
    margin-top: 2em;
    width: 90%;
    padding: 1em 0 2em;
  }

  .wrapper-breadcumbs > div {
    width: 90%;
    display: flex;
    flex-flow: wrap;
  }

  .bg-line1,
  .bg-line2,
  .bg-line3,
  .bg-line4 {
    max-width: 50px;
  }

  .introduction-maestria {
    flex-flow: wrap;
    flex-direction: column-reverse;
    padding: 0;
  }
  .introduction-maestria > div:first-child,
  .introduction-maestria > div:last-child {
    width: 100%;
  }
}
