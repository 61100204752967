.wrapper-carousel {
  height: auto;
  margin: auto;
  display: flex;
  overflow: scroll;
  height: auto;
  margin: auto;
}

.wrapper-carousel::-webkit-scrollbar {
  display: none;
}
.wrapper-carousel .flex-carousel-img {
  display: flex;
  align-items: center;
}

.wrapper-carousel .flex-carousel-img > div {
  width: 150px;
  min-width: 150px;
}

.wrapper-carousel .flex-carousel-img img {
  filter: grayscale(1);
  max-width: 140px;
}

.wrapper-carousel .flex-carousel-img img:hover {
  filter: grayscale(0);
}

.gallery-indicators-carousel .material-icons {
  color: #004270;
  font-size: 40px;
  cursor: pointer;
}

.gallery-indicators-carousel .material-icons:hover {
  color: #febe10;
}
