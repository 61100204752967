.image-text-container {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin: auto;
  align-items: center;
}

.text-column a {
  background-color: #febe10;
  padding: 10px 20px;
  max-width: max-content;
  border-radius: 4px;
  font-size: 10pt;
  font-weight: 700;
  text-decoration: none;
  color: #004270;
  transition: background-color 0.3s, color 0.3s;
}

.text-column a:hover {
  background-color: #fcc755;
  color: white;
}

.left-image .text-column {
  margin-right: 15%;
  margin-left: 7%;
}

.right-image .text-column {
  margin-left: 15%;
  margin-right: 7%;
}

@media (min-width: 800px) {
  .image-text-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 800px) {
  .right-image .text-column,
  .left-image .text-column{
    margin-left: 0;
    margin-right: 0;
    margin: auto;
    width: 85%;

  }

  .right-image .text-column{
    padding-bottom: 1em;
  }
}
