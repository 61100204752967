.banner-general {
  background-color: #e6e6e6;
  position: relative;
}

.banner-general .lema p.h3 {
  color: #004270;
  text-align: left;
  font-size: 30px;
  margin: 0;
  line-height: 1;
}

.banner-general .lema p.h2 {
  font-weight: 900;
  color: #febe10;
  font-size: 90px;
  line-height: 0.8;
  text-align: center;
}

.banner-general .lema p.h4,
.banner-sections h2 {
  font-weight: 400;
  text-align: right;
  font-size: 25px;
  margin: 0;
  line-height: 1;
}

.banner-general .lema {
  padding-bottom: 4em;
}
.banner-general .lema p.h4 b {
  font-weight: 900;
}

.banner-sections {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.banner-sections > div {
  width: 33%;
}

.banner-sections > div > div {
  margin: auto;
}
.banner-sections h2 {
  text-align: center;
}
.banner-sections a {
  border: 1px solid #febe10;
  border-radius: 15px;
  padding: 4px 20px;
  color: #004270;
  max-width: max-content;
  font-size: 10pt;
  margin: 10px 0;
  transition: 0.3s;
  display: flex;
  align-items: center;
  font-weight: 700;
  margin: auto;
}
.banner-sections a:hover {
  background-color: #febe10;
}
.banner-sections a .material-icons {
  padding-left: 10px;
}
.banner-general > div:first-child {
  max-width: 740px;
}
.banner-general .bg-line1 {
  position: absolute;
  left: 20%;
  top: 30%;
}

.banner-general .bg-line3 {
  position: absolute;
  right: 10%;
  top: 29%;
}

.banner-general .bg-line4 {
  position: absolute;
  left: 13%;
  top: 10%;
  transform: rotate(180deg);
}

@media screen and (max-width: 900px) {
  .banner-sections {
    flex-flow: wrap;
    width: 70%;
    margin: auto;
  }

  .banner-sections > div {
    width: 100%;
    padding-bottom: 30px;
  }

  .banner-general .lema p.h2 {
    font-size: 60px;
  }
  .banner-sections h2,
  .banner-sections a {
    font-size: 10pt;
  }
  .banner-general {
    padding: 2em 0;
  }

  .banner-general .bg-line1 {
    left: 0%;
  }

  .banner-general .bg-line3 {
    right: 0%;
  }

  .banner-general .bg-line4 {
    left: 0%;
  }
}
