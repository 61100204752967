.wrapper-menu {
  background-color: hsla(0, 0%, 100%, 0.98);
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: 0.3s;
  width: 100%;
  z-index: 6;
}

.menu-title {
  padding-right: 30px;
  color: white;
  font-weight: 100;
  font-size: 2em;
  cursor: pointer;
}
.menu-close {
  position: absolute;
  top: 10px;
  left: 4%;
  font-size: 3em;
  color: #004270;
  font-weight: 900;
  cursor: pointer;
}

.section-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.items-menu {
  width: 65%;
}
.form {
  width: 35%;
  padding-top: 2em;
}

.form h3 {
  padding: 0px 0px 5px 0px;
  font-size: 16pt;
}
.items-menu .title > div,
.items-menu .title > a {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #004270;
  text-transform: uppercase;
  font-weight: 800;
  color: #004270;
  cursor: pointer;
  padding: 5px 0;
}

.items-menu .material-icons{
  font-size: 15px;
}
.items-menu .title > div.active,
.options-menu .active,
.items-menu .title .active {
  color: #febe10;
  border-bottom: 1px solid #febe10;
}
.options-menu {
  width: 80%;
  margin: auto;
}
.options-menu a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #004270;
  color: #004270;
  padding: 8px 0;
  font-weight: 800;
}

.options-menu a:hover,
.wrapper-intranet a:hover,
.items-menu .title > div:hover {
  color: #febe10;
}

.options-menu > div {
  width: 90%;
  margin: auto;
}

.intranet {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.section-menuitemsquick .social {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-menuitemsquick img {
  width: 15px;
  padding: 0 10px;
}
.section-menuitemsquick img:hover {
  filter: contrast(0.7);
}

.section-menuitemsquick {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.accesos {
  background-color: #eaeaea;
}
.accesos > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accesos p {
  color: #4a4a4a;
  font-weight: 900;
}
.wrapper-menu .social {
  background-color: #004270;
  padding: 8px 0;
}
.section-menuitemsquick .accesos a {
  font-size: 11pt;
  padding: 8px 20px;
  font-weight: 400;
  display: block;
  text-align: center;
}
.accesos .items {
  width: 80%;
}
.wrapper-intranet {
  background: #febe10;
  width: 20%;
}
.accesos .wrapper-intranet a {
  font-weight: 700;
}
.wrapper-intranet a:hover {
  color: white;
}

.accesos .items {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-height: 500px) {
  .section-menuitemsquick {
    display: none;
  }
  .form {
    display: none;
  }
}

@media screen and (max-width: 900px) {

  .accesos .items{
    width: 70%;
  }
  .wrapper-intranet{
    width: 30%;
  }
  .mobile-quickitems {
    display: none!important;
  }
  .section-menuitemsquick .accesos a {
    font-size: 8pt;
    padding: 5px;
  }
  .form {
    display: none;
  }
  .items-menu {
    width: 100%;
  }
  .section-menuitemsquick .social {
    justify-content: center;
  }

  .section-menuitemsquick .social img {
    width: 20px;
  }
  .section-menuitemsquick p {
    display: none;
  }

  .accesos > div {
    align-items: stretch;
  }
  .section-menu {
    height: 100%;
  }
}




.menu {
  width: 250px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 8px;
}

.menu-list {
  list-style: none;
  padding: 0;
}



.submenu {
  padding-left: 15px;
  display: block;
}

.rotated {
  transform: rotate(90deg);
}