.wrapper-header {
  background-color: #004270;
  position: fixed;
  width: 100%;
  z-index: 4;
  top: 0;
}

.wrapper-header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.wrapper-header img.logo {
  width: 80px;
  height: auto;
}
.wrapper-header .modal-open:hover {
  color: #febe10;
}

.menu-items {
  display: flex;
  align-items: center;
}

.menu-items a {
  display: flex;
}
.menu-items .material-icons {
  font-size: 15px;
}
.menu-items a,
.menu-items p {
  color: white;
  cursor: pointer;
  font-size: 11pt;
  padding: 0 10px;
}
.menu-items a:hover,
.menu-items .active {
  color: #febe10;
}
.header-form {
  background-color: white;
  padding: 10px 0;
}

.header-form a {
  color: #004270;
  font-size: 8pt;
}
.logo {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .menu-items .header-opmobile {
    display: none;
  }

  .menu-items a,
  .menu-items p {
    font-size: 9pt;
  }
}
