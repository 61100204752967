.wrapper-pagos a {
  background-color: #febe10;
  color: #003f72;
  padding: 10px 30px;
  max-width: max-content;
  border-radius: 4px;
  margin-top: 1em;
  font-size: 11pt;
  font-weight: 700;
  transition: 0.3s;
}

.wrapper-pagos a:hover {
  color: white;
}

.wrapper-pagos p{
  font-size: 9pt;
    color: #8a8a8a;
    max-width: 600px;
}


.descuentos-section p{
  padding: 5px 0;
}

.descuentos-section p.yellow{
 
  font-size: 15pt;
  text-decoration: underline;
}

.descuentos-section .wrapper-blockimage > div.layout8 > div:first-child{
  width: 35%;
}

.descuentos-section .wrapper-blockimage > div.layout8 > div:last-child{
  width: 65%;
}