.select-academicoffer {
  background-color: #e6e6e6;
}
.input-academicoffer {
  padding-bottom: 2px;
  padding-top: 2px;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  min-height: 38px;
  width: 100%;
}
.wrapper-inputoffer {
  display: flex;
  align-items: center;
}

.wrapper-inputoffer .material-icons {
  color: #004270;
}

.wrapper-offer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 2em;
}

.wrapper-offer > div {
  width: 32%;
}

.wrapper-offer h2 {
  color: #004270;
  font-size: 18pt;
}

.wrapper-academicoffer {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-flow: wrap;
  transition: 0.3s;
  gap: 15px;
}
.wrapper-academicoffer > div {
  width: 270px;
  background-color: #fafafa;
  padding: 10px 0 60px;

  box-shadow: 0px 1px 1px 1px #fafafa;
  position: relative;
}

.wrapper-academicoffer > div:hover {
  background-color: #e8e8e8;
}
.wrapper-academicoffer h3 {
  font-size: 12pt;
  line-height: 1.1;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.wrapper-academicoffer p {
  color: #004270;
  padding: 0px;
  font-size: 11pt;
  line-height: 1.2;
}
.wrapper-academicoffer p b {
  font-weight: 500;
}
.wrapper-academicoffer .tag-2 {
  color: white !important;
  font-size: 11px;
  line-height: 1;
  max-width: max-content;
  padding: 5px 7px;
  border-radius: 3px;
  background: #e9b31f;
}

.wrapper-academicoffer .tag-cupos {
  color: white !important;
  font-size: 11px;
  line-height: 1;
  max-width: max-content;
  padding: 5px 7px;
  border-radius: 3px;
  background: #699d79;
}

.link-offer {
  background-color: #004270;
  padding: 10px;
  color: white;
  border-radius: 4px;
  font-size: 10pt;
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.link-offer .material-icons {
  color: #febe10;
  font-size: 15px;
  margin-left: 10px;
}

.link-offer:hover {
  background-color: #febe10;
  color: #004270 !important;
}

.link-offer:hover .material-icons {
  color: #004270;
}

.wrapper-contentoffer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 0 6em;
  position: relative;
  overflow: hidden;
}
.wrapper-contentoffer > div {
  width: 16%;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background: #004270;
}

.wrapper-contentoffer div.title {
  position: absolute;
  bottom: 7%;
  width: 100%;
}
.wrapper-contentoffer p {
  color: #febe10;
  font-weight: 900;
  font-size: 1.7em;
  width: 100%;
  text-align: center;
  line-height: 1.1;
  padding: 0;
}

.wrapper-contentoffer .tag {
  font-size: 10pt;
  padding-top: 3px;
  font-weight: 400;
  color: white;
}

.wrapper-contentoffer .tag1 {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 18pt;
}
.wrapper-contentoffer a:hover p {
  color: #febe10;
}
.wrapper-titleoffer {
  max-width: 800px;
  margin: auto;
  text-align: center;
  padding-bottom: 1em;
}
.wrapper-titleoffer h2 {
  font-size: 18pt;
}

.wrapper-titleoffer h2 b {
  font-size: 21pt;
}

.section-moreinfo {
  background: linear-gradient(to bottom, rgb(255 255 255 / 50%), white);
  padding-top: 5em;
  position: relative;
  top: -3em;
  backdrop-filter: blur(1px);
}
.wrapper-moreinfo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #004270;
  background-color: #febe10;
  max-width: max-content;
  margin: auto;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.wrapper-moreinfo .material-icons {
  font-size: 15px;
  margin-left: 1em;
}
/*offer by modelo*/

.offerbymodelo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.offerbymodelo > div:first-child {
  width: 22%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 1em 0;
  margin-bottom: 1em;
}

.offerbymodelo > div:last-child {
  width: 77%;
  position: relative;
  top: -44px;
}

.wrapper-view > div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.wrapper-view p {
  font-size: 9pt;
  color: #909090;
  padding: 0 4px 0 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.wrapper-view .material-icons {
  color: #909090;
  font-size: 25px;
}
.wrapper-view > div:hover p,
.wrapper-view > div:hover .material-icons {
  color: #004270;
}
.offerbymodelo label {
  color: #4a4a4a;
  padding-left: 5px;
  cursor: pointer;
  font-size: 11pt;
}

.offerbymodelo .item {
  padding: 3px 0;
  cursor: pointer;
}

.wrapper-link {
  position: absolute;
  bottom: 10px;
}
.wrapper-academicofferlist {
  margin-bottom: 2em;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: stretch;
}

.wrapper-academicofferlist > div {
  width: 49%;
  display: flex;
  border-bottom: 1px solid rgb(0 66 112 / 30%);
}
.wrapper-academicofferlist a {
  display: flex;
  align-items: center;
  padding: 5px 0;
  font-family: "Montserrat", sans-serif;
}

.wrapper-academicofferlist .material-icons {
  color: #cdcdcd;
  font-size: 15px;
}

.wrapper-academicoffer a:hover {
  color: #febe10;
}
@media screen and (max-width: 900px) {
  .wrapper-contentoffer .tag1 {
    height: auto;
  }
  .wrapper-contentoffer div.title {
    position: initial !important;
    background-color: #004270;
    padding: 15px 0;
    margin: 0 0 10px;
  }

  .wrapper-contentoffer p {
    font-size: 14pt;
  }
  .wrapper-titleoffer {
    width: 90%;
  }

  .wrapper-contentoffer {
    margin: 2em 0 4em;
  }
  .wrapper-contentoffer > div {
    opacity: 1 !important;
    transform: none !important;
  }

  .wrapper-offer > div {
    width: 100%;
  }

  .wrapper-contentoffer > div,
  .wrapper-academicoffer > div {
    width: 100%;
  }

  .wrapper-contentoffer img {
    display: none;
  }

  .offerbymodelo > div {
    width: 100% !important;
  }
  .offerbymodelo > div:first-child {
    box-shadow: none;
  }
  .wrapper-academicofferlist > div {
    width: 100%;
  }
  .offerbymodelo > div:last-child {
    top: 0;
  }
  .wrapper-academicofferlist {
    width: 100%;
  }
}
