.wrapper-floating {
  position: fixed;
  left: 0%;
  top: 47%;
  z-index: 4;
  background-color: #004270;
  border-radius: 0 5px 5px 0;
  padding: 2px;
}

.wrapper-floating:hover {
  background-color: rgb(5, 130, 49);
}
.wrapper-floating-2 {
  position: fixed;
  left: 0%;
  top: 40%;
  z-index: 4;
  background-color: #004270;
  border-radius: 0 5px 5px 0;
padding: 2px;
}
.wrapper-floating-2:hover {
  background-color: #2f9eed;
}
.wrapper-floating img,
.wrapper-floating-2 img {
  width: 90px;
}

@media screen and (max-width: 900px) {
  .wrapper-floating {
    top: 88%;
  }
  .wrapper-floating-2 {
    top: 82%;
  }
}
