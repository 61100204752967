.quote-container {
  background: #f9f9f9;
  border-left: 5px solid #8a8a8a;
  padding: 20px 0;
  position: relative;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.quote-text p {
  color: #8a8a8a;
  font-size: 20px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  text-align: left;
}

.quote-container img {
  width: 25px;
  opacity: 0.05;
  filter: grayscale(1);
}

@media (max-width: 900px) {
  .quote-text p {
    font-size: 12pt;
  }
}
