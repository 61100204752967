/*
Modal card
*/
.wrapper-modalfixed {
  position: absolute;
  right: 0;
  z-index: 3;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 210px;
  padding: 15px 20px;
  background-color: #004270;
  transition: 0.3s;
  box-shadow: 0px 1px 1px #ddd;
  border-radius: 4px 0 0 4px;
}

.wrapper-modalfixed:hover {
  background-color: #febe10 !important;
}
.wrapper-modalfixed p {
  color: white;
  font-size: 9pt;
  font-weight: 700;
  padding: 0;
}
.wrapper-modalfixed:hover p,
.wrapper-modalfixed:hover .material-icons {
  color: #004270 !important;
}

.wrapper-modalfixed .material-icons {
  color: white;
  font-size: 22px;
  margin-left: 20px;
  font-weight: 300;
}
/**/

.modal-open {
  cursor: pointer;
}

.modal-open:hover{
color: #e9b31f;
}

.modal-open .material-icons{
  font-size: 20px;
}
.relative-modalcard {
  position: relative;
}
.wrapper-modalcard {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0px;
  z-index: 5;
  right: 0;
  width: 300px;
  padding: 20px;
  cursor: pointer;
}

.wrapper-modalcard img {
  width: 40px;
  margin: 0 50px 0 10px;
}

.wrapper-modalcard p {
  font-size: 10pt;
  line-height: 1.2;
  color: white;
}

.wrapper-contentcard iframe {
  border: none !important;
}

.wrapper-contentcard {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  left: 0;
  overflow: auto;
  padding-top: 40px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.wrapper-contentcard > div {
  position: relative;
  width: 80%;
  max-width: 600px;
  margin: auto;
  height: auto !important;
}

.wrapper-contentcard p{
  font-size: 10pt;
  padding: 2px 0;
  font-weight: 400;
}

.wrapper-contentcard h4, .wrapper-contentcard h4 b{
  font-size: 14pt;
  padding: 5px 0;
}

.close-modalcard {
  position: absolute;
  right: -57px;
  color: #febe10;
  font-size: 50px;
  cursor: pointer;
  top: -8px;
}
@media screen and (max-width: 900px) {
  .wrapper-modalcard {
    position: initial;
    width: 100%;
    justify-content: center;
    transform: translateY(0px) translateZ(0px) !important;
    opacity: 1 !important;
    padding: 40px 0 !important;
    background-color: #004270 !important;
  }

  .wrapper-modalcard > div:first-child {
    width: 100%;
  }

  .wrapper-modalcard p {
    margin-left: 20%;
    text-align: center;
    line-height: 1.4;
  }

  .close-modalcard {
    right: -21px;
  }
  .wrapper-modalfixed {
    width: 100%;
    justify-content: center;
    position: initial;
    padding: 10px 0;
    border-radius: 0px 0px 0px 0px;
  }

  .wrapper-modalfixed p{
    margin-left: 5%;
  }
 
}
