.fixed-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 320px;
  background-color: white;
}
.fixed-notification-close {
  position: absolute;
  top: 0;
  right: 0;
}
.fixed-notification-close .material-icons {
  cursor: pointer;
  color: #004270;
  font-size: 25px;
}
.fixed-notification-close .material-icons:hover {
  color: #febe10;
}
.fixed-notification > div a {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.fixed-notification > div a > div {
  width: 50%;
}

.fixed-notification > div a > div{

  display: flex;
  align-items: center;
  flex-flow: wrap;
  padding: 10px 0;
}

.fixed-notification > div a > div:first-child{
  background-color: #febe10;
}
.fixed-notification:hover > div a > div:first-child {
background-color: #004270;

}
.fixed-notification:hover > div a > div:first-child p{
  color:white;

}

.notification1{
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 14pt;
}
.notification1 b{
  font-weight: 800;
  font-size: 18pt;
}

.notification3{
  font-size: 11pt;
}