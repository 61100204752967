.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
}

.block-columntitle .flex {
  align-items: center;
  overflow: hidden;
  position: relative;
}
.block-columntitle h2 {
  font-weight: 400;
}
.block-column .col5,
.block-column .col4,
.block-column .col3,
.block-column .col2, .block-column .col6 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.block-column .col3 > div {
  width: 32%;
}

.block-column .col2 > div {
  width: 48%;
}

.block-column .col4 > div {
  width: 24%;
}
.block-column .col5 > div{
  width: 19%;
}
.block-column .col6 > div {
  width: 16%;
}
.block-column .bg-line1{
  top:0;
  left: 0;
  position: absolute;
}

.block-column .bg-line2{
  top: 30%;
  left: 1%;
  position: absolute;
}

.block-column .bg-line3{
  bottom:30%;
  right: 1%;
  position: absolute;
}

.block-column .bg-line4{
  bottom:0;
  right: 0;
  position: absolute;
}

.block-column .content a {
  background-color: #febe10;
  padding: 10px 30px;
  max-width: max-content;
  border-radius: 4px;
  margin: 1em 0 2em;
  font-size: 11pt;
  font-weight: 700;
  transition: 0.3s;
  color: #004270;
}


.block-column li a{
  padding: 0;
  margin: 0;
  background: none;
  font-weight: 300;
  border-bottom: 1px solid #febe10;
}

.block-column li a:hover{
  border-bottom: 1px solid #004270;
}


.block-column .col4 > div img {
  width: 100%;
  transform: 0.3s ease-in-out;
  margin-bottom: 1em;
}

/* Efecto hover en la imagen */
.block-column .col4 > div:hover img {
  transform: scale(1.1);  /* Aumenta el tamaño de la imagen */
 
}

/* Efecto hover en el div */
.block-column .col4 > div {
  transition: border 0.3s ease, box-shadow 0.3s ease;
}
.block-column .col7 > div:hover,
.block-column .col4 > div:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Agrega sombra al div */
}


@media screen and (max-width: 900px) {
  .block-columntitle .flex > div {
    width: 100% !important;
  }
  .block-column .col6 > div,
  .block-column .col4 > div,
  .block-column .col3 > div,
  .block-column .col2 > div {
    width: 100%;
    margin-bottom: 1em;
  }

  .block-column .layout8{
    width: 100%;
    margin: auto;
  }
}
