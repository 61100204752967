.wrapper-conectus h2{
    text-align: center;
}

.wrapper-conectus p{
    text-align: center;
    font-size: 16pt;
    max-width: 800px;
    margin: auto;
}

.wrapper-conectus .wrapper-social{
    justify-content: center;
}

.wrapper-conectus .wrapper-social a{
   margin: 0 10px;
}
.wrapper-conectus .wrapper-social img{
    filter: contrast(0.3);
   }
.wrapper-conectus .wrapper-social a:hover img{
  filter: contrast(0.1);
 }