/* Galería principal */
.wrapper-gallery > div {
  height: auto;
  margin: auto;
  display: flex;
  overflow: hidden; /* Evita que los elementos salgan del contenedor */
  scroll-behavior: smooth; /* Desplazamiento suave */
  max-width: 100%;
}

.wrapper-gallery > div::-webkit-scrollbar {
  display: none;
}

/* Estilo de los elementos de la galería */
.item-gallery {
  flex: 0 0 100%; /* Cada elemento ocupa el 100% del ancho visible */
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-gallery img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Asegura que las imágenes se adapten */
  border-radius: 8px;
}

/* Indicadores de la galería */
.gallery-indicators {
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.gallery-indicators span {
  color: #febe10;
  font-size: 30px;
  cursor: pointer;
  border: 2px solid transparent;
  margin: 0 5px;
}

.gallery-indicators span.active {
  color: red;
}

.gallery-indicators span:hover {
  border: 2px solid #febe10;
}

/* Responsividad */
@media (max-width: 768px) {
  .gallery-indicators span {
    font-size: 20px; /* Reduce el tamaño de los indicadores en pantallas pequeñas */
  }

  .item-gallery img {
    border-radius: 4px; /* Ajusta el radio de las esquinas en pantallas pequeñas */
  }
}

