.wrapper-blockbackgroundimage {
  background-position: 50%;
  background-size: cover;
}

.wrapper-blockbackgroundimage > div {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
}
.wrapper-blockbackgroundimage > div > div {
  width: 50%;
  background-color: rgb(234 234 234 / 90%);
  box-shadow: 0px 1px 1px 1px #ddd;
  margin: 2em 0;
}

.wrapper-blockbackgroundimage a {
  background-color: #febe10;
  padding: 10px 30px;
  max-width: max-content;
  border-radius: 4px;
  margin-top: 2em;
  font-size: 11pt;
  font-weight: 700;
  transition: 0.3s;
}

.wrapper-blockbackgroundimage .content {
  padding: 20px 0;
}

.wrapper-blockbackgroundimage a:hover {
  color: white;
}

.portada {
  justify-content: flex-start !important;
  padding: 4em 0;
}
.portada > div {
  background: none !important;
  box-shadow: none !important;
}

.portada h2 {
  color: white;
  padding: 0;
}

.portada p {
  color: white;
}

.portada .content > div {
  width: 100%;
}

@media screen and (max-width: 900px) {
  .wrapper-blockbackgroundimage {
    background-image: none !important;
    background-color: #004270;
  }

  .wrapper-blockbackgroundimage > div > div {
    margin: auto;
    width: 100%;
  }

  .wrapper-blockimagecabecera {
    position: inherit !important;
    width: 100% !important;
    background-color: #004270 !important;
    padding: 2em 0;
  }
}
