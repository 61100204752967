.blockimagecabecera {
  position: relative;
}
.wrapper-blockimagecabecera {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: rgb(0 66 112 / 50%);
}

.wrapper-blockimagecabecera > div {
  display: flex;
  align-items: center;
  margin-left: 13%;
  margin-right: 10%;
  height: 100%;
}

.wrapper-blockimagecabecera h2,
.wrapper-blockimagecabecera p {
  color: white;
}


.wrapper-blockimagecabecera a , .blockimagecabecera-internas a{
  background-color: #febe10;
  padding: 10px 30px;
  max-width: max-content;
  border-radius: 4px;
  font-size: 11pt;
  font-weight: 700;
  transition: 0.3s;
  color:#004270;
}

.wrapper-blockimagecabecera a {
  margin: auto;
}
.blockimagecabecera-internas a{
margin: 1.5em auto;
}
.wrapper-blockimagecabecera a:hover, .blockimagecabecera-internas  a:hover{
  color: white;
}

/*new styles*/

.blockimagecabecera-internas{
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.blockimagecabecera-internas > div{
  width: 50%;
  display: flex;
  align-items: center;
}

.blockimagecabecera-internas .tag {
  display: flex;
  align-items: center;
  max-width: 250px;
}

.blockimagecabecera-internas .tag .material-icons {
  color: white;
  margin-right: 10px;
  font-size: 15px;
}
.blockimagecabecera h1{
  color:white;
  font-size: 30pt;
  font-weight: 700;
}
.blockimagecabecera-internas h2{
  text-align: center;
}

.blockimagecabecera-internas .tag{
  display: flex;
  align-items: center;
}
.blockimagecabecera-internas .tag p{
  font-size: 9pt;
  text-align: left;
}
.blockimagecabecera-internas p{
  text-align: center;
}
.blockimagecabecera-internas .icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blockimagecabecera-internas .icons > div {
  background-color: white;
  text-align: center;
  padding: 10px 0;
  margin: 1em 1em 0 0;
  border-radius: 4px;
  width: 90px;
}

.blockimagecabecera-internas .icons p {
  padding: 0;
  font-size: 9pt;
  font-weight: 500;
  color:#8a8a8a;
}

.blockimagecabecera-internas .icons .material-icons {
color: #8a8a8a;
}  

.blockimagecabecera{
  margin-top: 50px;
}

.wrapper-blockimagecabecera h4{
  font-size: 9pt;
  padding: 3px 0;
  color:white;
  font-weight: 400;
}

.wrapper-blockimagecabecera h4 b{
  font-size: 10pt;
  padding: 3px 0;
  color:#febe10;

}

@media screen and (max-width: 900px) {

  .wrapper-blockimagecabecera > div {
    width: 90%;
    margin: auto;
  }


  .wrapper-blockimagecabecera h2 {
    text-align: center;
    font-size: 16pt;
  }
  .wrapper-blockimagecabecera h2 b, .wrapper-blockimagecabecera h2 strong{
    font-size: 16pt;
  }

  .wrapper-blockimagecabecera p {
    text-align: center;
  }

  .wrapper-blockimagecabecera a {
    margin: auto;
    margin-top: 1em;
  }

  .blockimagecabecera-internas{
    flex-flow: wrap;
    flex-direction: column-reverse;
  }

  .blockimagecabecera-internas > div{
    width: 100%;
  }
.blockimagecabecera-internas > div > div{
  padding: 1em 0;
}
  .blockimagecabecera-internas p {
    font-size: 12pt;
}

.blockimagecabecera-internas .icons{
  padding-bottom: 1em;
}

  
}
