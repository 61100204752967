#root,
body,
html,
.wrapper-fixedheader {
  height: 100%;
  margin: 0;
}

.wrapper-fixedheader {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  position: relative;
}

.wrapper-fixedheader video {
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.wrapper-contentheader {
  position: absolute;
  bottom: 5%;
  width: 100%;
  text-align: center;
}

.wrapper-contentheader p {
  color: white;
  font-size: 10pt;
}
.wrapper-contentheader .material-icons {
  color: white;
}
.wrapper-lemaheader {
  position: absolute;
  bottom: 200px;
  width: 100%;
}
.wrapper-lemaheader > div {
  display: flex;
  justify-content: flex-end;
  width: 60%;
  margin: auto;
  align-items: flex-end;
}

.wrapper-lemaheader > div > div {
  max-width: 600px;
}

.wrapper-lemaheader p {
  line-height: 1;
  padding: 0;
  color: white;
  font-family: "Montserrat", sans-serif;
}
.wrapper-lemaheader p.span0 {
  font-size: 30pt;
  font-weight: 500;
}
.wrapper-lemaheader p.span1 {
  font-size: 27pt;
  font-weight: 600;
  color:#febe10;
  line-height: 1.2;
}
.wrapper-lemaheader p.span2 {
  font-size: 22pt;
  font-weight: 600;
  color:#febe10;
}
.wrapper-lemaheader p.span2 b {
  font-weight: 900;
  font-size: 26pt;
  color:#febe10;
}
.wrapper-lemaheader p.span3 {
  font-size: 36pt;
  font-weight: 700;
}

.wrapper-lemaheader p.span4 {
  font-family: "Montserrat", sans-serif;
  font-size: 36pt;
  color: #febe10;
  font-weight: 900;
  letter-spacing: 1.5px;
}
.wrapper-lemaheader div.bloque1 p{
  font-size: 180pt;
  font-family: auto;
  font-weight: 500;
}