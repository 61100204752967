.block-herramientas {
  position: relative;
}

.block-herramientas > div.layout8 {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  align-items: center;
  padding: 2em 0;
}

.block-herramientas > div.layout8 > div {
  width: 48%;
}

.block-herramientas-image {
  position: relative;
  height: 100%;
}
.bg-herramientas {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 80%;
  z-index: 2;
}

.bg-herramientas > div {
  width: 80%;
  height: 100%;
  margin: auto;
  border-radius: 30px;
}
.block-herramientas-image > img {
  max-width: 80%;
  margin: auto;
  position: relative;
  z-index: 3;
  border-radius: 0 0 30px 30px;
}

.block-herramientas .content-herramientas a {
    background-color: #febe10;
    padding: 10px 30px;
    max-width: max-content;
    border-radius: 4px;
    margin: 1em 0 2em;
    font-size: 11pt;
    font-weight: 700;
    transition: 0.3s;
    color:#004270
}

.block-herramientas .content-herramientas a:hover {
 color:white;
}

.block-herramientas .bg-line2 {
  position: absolute;
  left: 0%;
  bottom: 4%;
}

.block-herramientas .bg-line3 {
  position: absolute;
  top: 30%;
  bottom: 15%;
  right: 0%;
}

.block-herramientas .bg-line4 {
  position: absolute;
  left: 0%;
  top: 21%;
  transform: rotate(180deg);
}

#bg-1 {
  background-color: #0a0b3f;
}

#bg-1 h2,
#bg-1 h3,
#bg-1 p,
#bg-1 a {
  color: white;
}

.action-herramientas {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
  padding: 20px 0;
}
.action-herramientas > img {
  width: 30px;
}

.action-herramientas a {
  color: white;
  padding-right: 15px;
  display: block;
}

@media screen and (max-width: 900px) {
  .block-herramientas .layout8 {
    width: 100%;
  }
  .content-herramientas {
    width: 70% !important;
    margin: auto;
  }

  .content-herramientas > div {
    opacity: 1 !important;
    transform: translateY(0px) translateZ(0px) !important;
  }

  .block-herramientas-image {
    width: 100% !important;
  }
  .bg-herramientas > div {
    border-radius: 0px;
  }
  .block-herramientas > div.layout1 {
    padding: 3em 0 0;
  }

  .block-herramientas .bg-line2 {
    left: 0%;
    top: 10%;
  }

  .block-herramientas .bg-line3 {
    right: 0%;
  }

  .block-herramientas .bg-line4 {
    left: 0%;
  }

  .servicios-presencial > div > div {
    width: 45% !important;
  }
  .block-herramientas h2 {
    font-size: 20pt;
  }

  .block-herramientas h3 {
    font-size: 20pt;
  }

  .bg-herramientas > div {
    width: 100%;
  }

  .block-herramientas .bg-line3,
  .block-herramientas .bg-line4 {
    top: 5%;
  }
}
