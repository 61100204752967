/* General Styles */
.accordion {
  width: 100%;
  border-bottom: 1px solid rgba(0, 66, 112, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #004270;
  font-weight: 700;
  padding: 10px 0;
}

.accordion-header:hover {
  color: #febe10;
}

.accordion .material-icons {
  font-size: 20px;
  transition: color 0.3s ease;
}

.accordion.active .material-icons {
  color: #febe10;
}

/* Content Styles */
.accordion-content {
  transition: max-height 0.4s ease, padding 0.4s ease;

}

.accordion-content.show {
  padding: 10px 0;
}

.accordion-content .content-inner {
  color: #004270;
  font-size: 14px;
  line-height: 1.5;
}

.accordion-content a, 
.text-column .accordion-content  a,
.block-columntext  .accordion-content a {
  color: #004270;
  font-weight: 700;
  text-decoration: none;
  transition: color 0.3s ease;
  background: none;
  padding: 3px 0;
  margin: 0;
  border-bottom: none;
}

.bluebg .block-columntext  .accordion-content a {
  color: white;

}

.accordion-content a:hover ,
.wrapper-blockimage .accordion-content a:hover,
.block-columntext  .accordion-content a:hover{
  color: #febe10;
}

.accordion-content p{
  font-size: 11pt;
  padding: 3px 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .accordion-header {
    font-size: 14px;
  }

  .accordion .material-icons {
    font-size: 18px;
  }
}
