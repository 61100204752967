.flip-card {
  background-color: transparent;
  width: 210px;
  height: 210px;
  perspective: 1000px;
  margin: 10px;
}
.flip-card p{
  font-size: 11pt;
  line-height: 1.1;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.flip-card-back > div {
  padding: 1em 0;
}



.flip-card-back a{
  background-color: #febe10;
    padding: 10px 30px;
    max-width: max-content;
    border-radius: 4px;
    margin: 1em auto 0;
    font-size: 11pt;
    font-weight: 700;
    transition: 0.3s;
    color: #004270;
}
.wrapper-autoridades-academicas .flip-card-back a{
  background-color: transparent;
  margin: 0;
  padding: 0;
  color: white;
}
.flip-card-back a:hover{
  color:white;
}

@media screen and (max-width: 900px) {
  .gobernanza-autoridades .flip-card {
    width: 160px;
    height: 160px;
  }
}
